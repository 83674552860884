<template>
  <NoSSR>
    <section :class="!inModal ? 'video_player editorial-content' : '-modal'">
      <div :class="{ 'container text-center': !inModal }">
        <div :class="{ 'row justify-content-center': !inModal }">
          <div :class="!inModal ? 'col-12' : ''">
            <Observer name="fade-up" @enter="enter" @leave="leave" rootMargin="0px">
              <vue-plyr ref="plyr" :options="plyrOption">
                <video :muted="isMobile" v-if="item.coverUrl" :poster="item.coverUrl" :src="injected ? item.url : ''" crossorigin="anonymous" playsinline>
                  <track v-if="item.subtitle && item.subtitle.src && item.subtitle.srclang" kind="captions" :label="item.subtitle.label" :srclang="item.subtitle.srclang" :src="item.subtitle.src" default />
                </video>
                <video :muted="isMobile" v-else :src="injected ? item.url : ''" crossorigin="anonymous" playsinline>
                  <track v-if="item.subtitle && item.subtitle.src && item.subtitle.srclang" kind="captions" :label="item.subtitle.label" :srclang="item.subtitle.srclang" :src="item.subtitle.src" default />
                </video>
              </vue-plyr>
            </Observer>
          </div>
        </div>
      </div>
    </section>
  </NoSSR>
</template>

<script>
import NoSSR from 'src/themes/zenith/node_modules/vue-no-ssr'
// import 'plyr/dist/plyr.css'

if (process.browser) {
  var VuePlyr = require('vue-plyr/dist/vue-plyr.ssr.js')
}

export default {
  props: ['item', 'playFullscreen', 'inModal', 'launch', 'pagePosition', 'siblings-components'],
  name: 'PlayerVideo',
  components: {
    VuePlyr,
    NoSSR
  },
  watch: {
    launch () {
      this.play()
    }
  },
  data() {
    return {
      isMobile: false,
      muted: false,
      injected: false,
      plyrOption: {
        controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'airplay', 'fullscreen', 'captions'],
        captions: {
          active: !!(this.item.subtitle && this.item.subtitle.srclang),
          language: (this.item.subtitle && this.item.subtitle.srclang) ? this.item.subtitle.srclang : 'en',
          fullscreen: {
            enabled: false,
            fallback: true,
            iosNative: false,
            container: null,
          },
        },
      },
    }
  },
  computed: {
    plyr() {
      return this.$refs.plyr.player
    },
  },
  mounted() {
    this.isMobile = window.innerWidth < 768
  },
  methods: {
    play() {
      setTimeout(() => {
        this.plyr.play()
      }, 500)
    },
    enter() {
      if (!this.injected) {
        this.injected = true
      }
    },
    leave() {}
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

section {
  margin: 10rem 0;
  color: var(--zenith-black);
  &.-modal {
    margin: 0;
    div {
      width: 100%;
    }
    /deep/ .plyr {
      margin-top: 2rem;
    }
  }
  &.editorial-content + .editorial-content {
    margin-top: -6rem;

    @include bp(max md) {
      margin-top: -2rem;
    }
  }
  @include bp(max md) {
    margin-bottom: 6rem;
    margin-top: 6rem;
  }
}

/deep/ .plyr {
  margin-bottom: 2rem;

  &:not(.plyr__tab-focus) {
    .plyr__controls {
      opacity: 0;
    }

    &:hover {
      .plyr__controls {
        opacity: 1;
      }
    }
  }

  &--full-ui input[type='range'] {
    &.plyr__tab-focus {
      &::-webkit-slider-runnable-track {
        outline: 1px dashed var(--zenith-white);
      }

      &::-moz-range-track {
        outline: 1px dashed var(--zenith-white);
      }

      &::-ms-track {
        outline: 1px dashed var(--zenith-white);
      }
    }
  }

  &__controls {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);

    .plyr__tab-focus {
      outline: 1px dashed var(--zenith-white);
    }
  }

  &__poster {
    background-size: cover;
  }

  &__control--overlaid {
    background: var(--zenith-white);
    &:hover {
      background: transparent;
      svg {
        color: var(--zenith-white);
      }
    }
    svg {
      color: var(--zenith-black);
    }
  }
}

.hide-controls {
  /deep/ .plyr {
    .plyr__controls {
      opacity: 0 !important;
      pointer-events: none;
    }
  }
}
</style>
